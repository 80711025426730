<template>
  <div>
    <div v-if="totalReviews < 1">
      <div class="row">
        <div class="col">
          <div v-if="loadingReviews" class="text-center">
            <b-spinner></b-spinner>
          </div>
          <h3 v-else class="text-center mt-5 d-block">We are currently waiting for your reviews</h3>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-12">
        <div v-if="!forDashboard" class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div class="form-inline">
              <label>
                Show&nbsp;
              </label>
              <b-form-select
                  v-model="datatable.queries.per_page"
                  class="ml-1 mr-1"
                  size="sm"
                  :options="[10, 15, 25, 50, 100]"
                  @change="getReviews"
              ></b-form-select>
              <label class="ml-3">
                Review Filter:&nbsp;
              </label>
              <b-form-select
                  v-model="datatable.queries.review_rating"
                  class="ml-1 mr-1 status-filter"
                  size="sm"
                  :options="[{ value: 1, text: '1 Star' },{ value: 2, text: '2 Stars' },{ value: 3, text: '3 Stars' },{ value: 4, text: '4 Stars' },{ value: 5, text: '5 Stars' }, { value: 0, text: 'All' }]"
                  @change="getReviews"
              ></b-form-select>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="dataTables_filter text-md-right">
              <vue-json-to-csv :json-data="reviews" :csv-title="csvTitle()">
                <button class="btn btn-dark-blue btn-sm mr-3"><Feather class="icon-xs" type="download" /> Export</button>
              </vue-json-to-csv>
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                    v-model="datatable.queries.search"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
              <button
                  class="ml-1 btn btn-dark-blue btn-sm"
                  @click="getReviews"
              >
                <i class="uil uil-search-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="card rwg-app-panel">
          <div class="card-body">
            <template v-if="!loadingReviews">
              <div class="table-responsive mb-0">
                <b-col v-if="!forDashboard">
                  <h4 >Reviews: {{ meta.business_title }}</h4>
                </b-col>
                <b-col v-if="!forDashboard">
                  <div id="rwg-review-header" class="rwg-review-header">
                    <div class="rwg-review-header-left">
                      <div class="rwg-posted-on-source"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272 92" width="87" height="30">
                        <path fill="#EA4335" d="M115.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18C71.25 34.32 81.24 25 93.5 25s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44S80.99 39.2 80.99 47.18c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"></path>
                        <path fill="#FBBC05" d="M163.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.85 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"></path>
                        <path fill="#4285F4" d="M209.75 26.34v39.82c0 16.38-9.66 23.07-21.08 23.07-10.75 0-17.22-7.19-19.66-13.07l8.48-3.53c1.51 3.61 5.21 7.87 11.17 7.87 7.31 0 11.84-4.51 11.84-13v-3.19h-.34c-2.18 2.69-6.38 5.04-11.68 5.04-11.09 0-21.25-9.66-21.25-22.09 0-12.52 10.16-22.26 21.25-22.26 5.29 0 9.49 2.35 11.68 4.96h.34v-3.61h9.25zm-8.56 20.92c0-7.81-5.21-13.52-11.84-13.52-6.72 0-12.35 5.71-12.35 13.52 0 7.73 5.63 13.36 12.35 13.36 6.63 0 11.84-5.63 11.84-13.36z"></path>
                        <path fill="#34A853" d="M225 3v65h-9.5V3h9.5z"></path>
                        <path fill="#EA4335" d="M262.02 54.48l7.56 5.04c-2.44 3.61-8.32 9.83-18.48 9.83-12.6 0-22.01-9.74-22.01-22.18 0-13.19 9.49-22.18 20.92-22.18 11.51 0 17.14 9.16 18.98 14.11l1.01 2.52-29.65 12.28c2.27 4.45 5.8 6.72 10.75 6.72 4.96 0 8.4-2.44 10.92-6.14zm-23.27-7.98l19.82-8.23c-1.09-2.77-4.37-4.7-8.23-4.7-4.95 0-11.84 4.37-11.59 12.93z"></path>
                        <path fill="#4285F4" d="M35.29 41.41V32H67c.31 1.64.47 3.58.47 5.68 0 7.06-1.93 15.79-8.15 22.01-6.05 6.3-13.78 9.66-24.02 9.66C16.32 69.35.36 53.89.36 34.91.36 15.93 16.32.47 35.3.47c10.5 0 17.98 4.12 23.6 9.49l-6.64 6.64c-4.03-3.78-9.49-6.72-16.97-6.72-13.86 0-24.7 11.17-24.7 25.03 0 13.86 10.84 25.03 24.7 25.03 8.99 0 14.11-3.61 17.39-6.89 2.66-2.66 4.41-6.46 5.1-11.65l-22.49.01z"></path>
                      </svg> <span class="source-rating-text">Rating</span></div>
                      <div v-if="meta" class="rwg-review-header-bottom">
                        <div class="rwg-review-avg-header">{{ meta.average }}</div>
                        <div class="rwg-review-rating">
                          <div id="rwg-floating-rating" class="rating-upper" :style="'width:' + (meta.average / 5) * 100 + '%'"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div>
                          <div class="rating-lower"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div>
                        </div>
                        <div class="rwg-review-total">{{meta.total}} reviews</div>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-table
                    :items="reviews"
                    :fields="datatable.columns"
                    :sort-by.sync="datatable.queries.sort_by"
                    :sort-desc.sync="datatable.queries.sort_desc"
                    :per-page="datatable.queries.per_page"
                    data-export-types="['csv']"
                    :no-local-sorting="true"
                    thead-class="d-none"
                    :borderless="true"
                    @sort-changed="sortChanged"
                >

                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(review)="row">
                    <Review :review="row.item" class="position-relative">
                      <div v-if="row.item.reply || row.item.writing_reply" class="mt-4">
                        <!-- eslint-disable-next-line-->
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="w-100">
                            <div v-if="row.item.waiting_4_ai || row.item.writing_reply && !row.item.editing">
                              <div v-if="row.item.waiting_4_ai">
                                Waiting for AI to write a reply <b-spinner small />
                              </div>
                              <!-- eslint-disable-next-line-->
                              <TypeWriter v-else-if="!row.item.editing && row.item.reply" :typing-speed="35" :text="row.item.reply" @completed="row.item.stopped_typing = true" />
                            </div>
                            <textarea v-else-if="row.item.editing" v-model="row.item.reply" :disabled="!row.item.editing" class="form-control"></textarea>
                            <div v-else-if="user.business.is_reply_engine">
                              <strong class="d-block">Reply:</strong>
                              {{row.item.reply}}
                            </div>
                          </div>
                          <div class="ml-2 h-25">
                            <b-button v-if="user.business.is_reply_engine && !row.item.waiting_4_ai && row.item.stopped_typing" variant="dark" @click="row.item.editing = true">Edit</b-button>
                          </div>
                        </div>
                      </div>
                      <div class="mb-2">
                        <span v-if="user.business.is_reply_engine">
                          <b-button v-if="!row.item.waiting_4_ai" :disabled="row.item.working" size="sm" class="mt-2 mr-2" @click="createReply(row.item)">
                          Write Reply
                          <b-spinner v-if="row.item.working" small />
                          </b-button>
                          <b-button v-if="row.item.reply && !row.item.waiting_4_ai" variant="info" :disabled="row.item.working" size="sm" class="mt-2 mr-2" @click="rewriteReply(row.item)">
                          Re-write Reply
                          <b-spinner v-if="row.item.working" small />
                          </b-button>
                          <b-button v-if="row.item.reply && !row.item.waiting_4_ai" variant="primary" :disabled="row.item.working" size="sm" class="mt-2 mr-2" @click="saveReply(row.item)">
                            <span v-if="row.item.reply_updated">Update Reply to Google</span>
                            <span v-else>Post Reply to Google</span>
                            <b-spinner v-if="row.item.working" small />
                          </b-button>
                          <b-button v-if="isAdmin" size="sm" class="mt-2 mr-2" variant="default" @click="resendEmail(row.item)">
                          Send Review Email
                          <b-spinner v-if="row.item.working" small />
                          </b-button>
                        </span>
                        <span v-if="user.business.is_social_review">
                          <b-button v-if="user.business.facebook_token && user.business.facebook_token.fb_page_id" size="sm" :class="{ 'bg-success': row.item.facebook_post, 'border-success': row.item.facebook_post  }" class="mt-2 mr-2 bg-primary border-primary" @click="confirmPostSocial(row.item,'facebook')">
                            <span v-if="row.item.facebook_post"><b-spinner v-if="row.item.working" small /> Posted</span><span v-else>Post</span> Image to Facebook</b-button>
                          <b-button v-if="user.business.facebook_token && user.business.facebook_token.ig_identifier" size="sm" :class="{ 'bg-success': row.item.instagram_post, 'border-success': row.item.instagram_post  }" class="mt-2 mr-2 bg-primary border-primary" @click="confirmPostSocial(row.item,'instagram')">
                            <span v-if="row.item.instagram_post"><b-spinner v-if="row.item.working" small /> Posted</span><span v-else>Post</span> Image to Instagram</b-button>
                           <b-button v-if="user.has_premium_plan" size="sm" class="mt-2 bg-primary border-primary" @click="confirmPostSocial(row.item,'gbp')">
                            <span v-if="row.item.gbp_post">
                              Posted</span><span v-else>Post</span> Image to Google <b-spinner v-if="row.item.working" small />
                           </b-button>
                        </span>
                      </div>
                      <div v-if="row.item.reply_updated && user.business.is_reply_engine" class="text-danger">
                        Reply Posted to Google: {{row.item.reply_updated}}
                      </div>
                      <div v-if="row.item.gbp_post" class="text-danger">
                        Image Posted to Google: {{row.item.gbp_post.front_date}}
                      </div>
                      <div v-if="row.item.openai_error" class="font-weight-bold text-danger">
                        {{row.item.openai_error}}
                      </div>
                    </Review>
                  </template>

                </b-table>
                <b-modal v-model="showSocialPostModal" :title="`Are you sure you want to post to ${modalTitle(postingType)}`">
                  <div v-if="postingItem">
                    <div><strong>Review Text: </strong>
<!-- eslint-disable-next-line -->
                      <span v-html="postingItem.review_text"></span></div>
                    <div v-if="postingType === 'instagram' && postingItem.instagram_post" class="mt-3">
                      Posted to Instagram on {{postingItem.instagram_post.front_date}}<br />
                      Link: <a :href="'https://www.instagram.com/p/' + postingItem.instagram_post.ig_shortcode" target="_blank">https://www.instagram.com/p/{{postingItem.instagram_post.ig_shortcode}}</a>
                    </div>
                    <div v-if="postingType === 'facebook' && postingItem.facebook_post" class="mt-3">
                      Posted to Facebook on {{postingItem.facebook_post.front_date}}<br />
                      Link: <a :href="'https://www.facebook.com/' + postingItem.facebook_post.post_id" target="_blank">https://www.facebook.com/{{postingItem.facebook_post.post_id}}</a>
                    </div>
                  </div>
                  <template v-slot:modal-footer>
                    <b-button variant="danger" @click="showSocialPostModal = false"
                    >Cancel</b-button
                    >
                    <b-button variant="success" @click="postSocial(postingItem,postingType)"><b-spinner v-if="postingItem.working" small /> Post</b-button>
                  </template>
                </b-modal>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalReviews }} reviews
                  </div>
                  <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                          v-model="datatable.queries.page"
                          :total-rows="totalReviews"
                          :per-page="datatable.queries.per_page"
                          @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv';
import TypeWriter from '@components/Common/TypeWriter.vue';
import Review from "@components/Common/Review.vue";
import Vue from "vue";
export default {
  components:{
    VueJsonToCsv,
    TypeWriter,
    Review
  },
  props:{
    defaultPerPage:{
      type:Number,
      default:10
    },
    forDashboard:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      json_export_labels:{

      },
      datatable: {
        items: [],
        columns: [
          { key: 'review'}
        ],
        queries: {
          per_page: this.defaultPerPage,
          page: 1,
          status: 'active',
          review_rating:'0',
          search: null,
          sort_by: 'review_timestamp',
          sort_desc: true,
          location_reviews: true
        },
      },
      showDeleteModal: false,
      selectedBusiness: null,
      loadingReviews: true,
      loadingImpersonate: false,
      loadingRemove: null,
      showWelcomeModal: false,
      showSocialPostModal: false,
      postingItem: null,
      postingType:''
    }
  },

  computed: {
    reviews() {
      return this.$store.getters['reviews/all'] || []
    },

    totalReviews() {
      return this.$store.getters['reviews/total']
    },
    meta() {
      return this.$store.getters['reviews/meta']
    },
    reload() {
      return this.$store.getters['reviews/reload']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    isAdmin(){
      return localStorage.getItem('main_access_role') === 'Admin';
    },
    socialImageExample(){
      return (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API_LOCAL) + `api/v1/sample-social-image?text=${encodeURI(this.postingItem.review_text)}&reviewer=${encodeURI(this.postingItem.author_title)}&business_name=${this.user.business.business_name}&business_phone=${this.user.business.phone_number}&icon=${this.postingItem.author_image}`;
    },
  },

  watch: {
    reload: (val) => {
      console.log(val)
    },
  },

  created() {
    this.$store.dispatch('reviews/setCurrentPage', 'index')
  },

  mounted() {
    this.getReviews();
    this.$store
        .dispatch('auth/getProfile')
        .then((user) => {
          console.log(user);
        })
        .catch(() => {

        })
  },

  methods: {
    modalTitle(postingType){
      let title = postingType.charAt(0).toUpperCase() + postingType.slice(1);
      if(postingType === 'gbp'){
        title = "Google Business Profile";
      }
      return title;
    },
    confirmPostSocial(review, type){
      if(!this.meta.can_social_post) {
        Vue.$toast.open({
          message: "Sorry, you have reached your post limit.",
          type: 'error',
          duration: 5000,
        });
        return;
      }
      this.postingItem = review;
      this.postingType = type;
      this.showSocialPostModal = true;
    },
    postSocial(review, type){
      review.working = true;
      this.$store
          .dispatch('reviews/postSocial', {
            type:type,
            review_id: review.id,
            user_id: this.user.id,
            business_id: this.user.business.id
          })
          .then((res) => {
            review.facebook_post = res.facebook_post;
            review.instagram_post = res.instagram_post;
            review.gbp_post = res.gbp_post;
            review.working = false;
            this.showSocialPostModal = false;

          })
          .catch((res) => {
            review.working = false;
            console.log(res);
          })
    },
    resendEmail(review){
      review.working = true;
      this.$store
          .dispatch('reviews/resendEmail', {
            review_id: review.id,
            user_id: this.user.id
          })
          .then((res) => {
            if(res.reply !== ''){
              review.reply = res.reply;
            }
            review.working = false;
          })
          .catch((res) => {
            review.working = false;
            console.log(res);
          })
    },
    csvTitle(){
      return 'showmyrep-reviews'+Date.now()
    },
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getReviews()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getReviews()
    },

    getReviews() {
      this.loadingReviews = true
      this.$store
          .dispatch('reviews/getAll', {
            queries: this.datatable.queries,
            page: 'index',
            businessId: this.user.business?.id,
          })
          .then((res) => {
            this.business = res.business;
            this.loadingReviews = false
          })
          .catch(() => {
            this.loadingReviews = false
          })
    },
    createReply(review){
      if(!this.meta.can_reply_engine) {
        Vue.$toast.open({
          message: "Sorry, you have reached your reply limit.",
          type: 'error',
          duration: 5000,
        });
        return;
      }
      review.working = true;
      review.writing_reply = true;
      review.waiting_4_ai = true;
      review.stopped_typing = false;
      review.openai_error = null;
      review.editing = false;
      this.$store
          .dispatch('reviews/getReply', {
            review: review.review_text,
            reviewers_name: review.author_title,
            review_stars: review.review_rating+" out of 5 stars. "
          })
          .then((res) => {
            console.log(res.data);
            review.reply = res.data.reply;
            review.working = false;
            review.waiting_4_ai = false;
            if(res.data.error){
              review.openai_error = res.data.error;
            }
          })
          .catch(() => {
            review.working = false;
            review.writing_reply = false;
            review.waiting_4_ai = false;
            review.stopped_typing = true;
          })
    },
    rewriteReply(review){
      if(!this.meta.can_reply_engine) {
        Vue.$toast.open({
          message: "Sorry, you have reached your reply limit.",
          type: 'error',
          duration: 5000,
        });
        return;
      }
      review.working = true;
      review.writing_reply = true;
      review.waiting_4_ai = true;
      review.stopped_typing = false;
      review.openai_error = null;
      review.editing = false;
      this.$store
          .dispatch('reviews/rewriteReply', {
            review: review.review_text,
            reviewers_name: review.author_title,
            review_stars: review.review_rating+" out of 5 stars. "
          })
          .then((res) => {
            console.log(res.data);
            review.reply = res.data.reply;
            review.working = false
            review.waiting_4_ai = false;
            if(res.data.error){
              review.openai_error = res.data.error;
            }
          })
          .catch(() => {
            review.working = false;
            review.writing_reply = false;
            review.waiting_4_ai = false;
            review.stopped_typing = true;
          })
    },
    saveReply(review){
      review.working = true;
      this.$store
          .dispatch('reviews/saveReply', {
            review: review
          })
          .then((res) => {
            console.log(res.data);
            review.working = false;
            review.editing = false;
          })
          .catch(() => {
            review.working = false
          })
    },
  },
}
</script>
<style lang="scss" scoped>
  .status-filter{
    text-transform: capitalize;
  }
</style>
